module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"petite and minimal","short_name":"pm","start_url":"/","background_color":"#96A192","theme_color":"#96A192","display":"minimal-ui","icon":"src/images/PM.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-commercelayer/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
