// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customerservice-js": () => import("./../src/pages/customerservice.js" /* webpackChunkName: "component---src-pages-customerservice-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-responsibility-js": () => import("./../src/pages/responsibility.js" /* webpackChunkName: "component---src-pages-responsibility-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-catalog-template-js": () => import("./../src/templates/catalog-template.js" /* webpackChunkName: "component---src-templates-catalog-template-js" */),
  "component---src-templates-category-template-js": () => import("./../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-product-template-js": () => import("./../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

